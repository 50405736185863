import axios from 'axios'

const API = '/manager/viviendas'

const Viviendas = {
  //Tipos
    listarTipos(params){
      return axios.get(`${API}/listar-tipos`,{params})
    },
    guardarTipo(payload){
      return axios.post(`${API}/guardar-tipo`,payload)
    },
    eliminarTipo(params){
      return axios.delete(`${API}/eliminar-tipo`,{params})
    },

  //Grupos
    listarGrupos(params){
      return axios.get(`${API}/listar-grupos`,{params})
    },
    guardarGrupo(payload){
      return axios.post(`${API}/guardar-grupo`,payload)
    },
    eliminarGrupo(params){
      return axios.delete(`${API}/eliminar-grupo`,{params})
    },
    listarViviendasGrupo(params){
      return axios.get(`${API}/listar-viviendas-grupo`,{params})
    },
    guardarVivienda(payload){
      return axios.post(`${API}/guardar-vivienda`,payload)
    },


    importarExcel(form){
      return axios.post(`${API}/tipo/importar`, form)
    },
    importarExcelEstructura(form){
      return axios.post(`${API}/estructura/importar`, form)
    },

}

export default Viviendas
