<template>
    <section v-loading="loading">
		<div class="custom-scroll overflow-auto px-5" style="height:calc(100vh - 180px);">
			<div class="row mx-0">
				<div class="col-xl-5 col-lg-8 col-md-10 col-sm-10 col-10 px-0">
					<div class="row mx-0 mb-5">
						<div class="col-auto pl-0 text-black f-20 f-600">
							Tipo de viviendas
						</div>
						<div class="col-auto mx-3 ml-auto">
							<el-dropdown>
								<button class="el-dropdown-link br-4 h-32px f-14 d-middle-bt w-140px border px-2 drop-plantilla">
									Plantilla<i class="el-icon-arrow-down el-icon--right"></i>
								</button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item>
										<export-excel
										:data="examplePlantilla"
										worksheet="a"
										name="plantilla.xls"
										>
										</export-excel>
									</el-dropdown-item>
									<el-dropdown-item >
										<p @click="modalImportar">
											Importar plantilla
										</p>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<div class="row mx-0">
						<div class="col pl-0">
								<p class="f-12 text-general pl-3">Nombre del tipo</p>
								<el-input v-model="tipo.nombre" show-word-limit  maxlength="30" class="w-100" size="small" />
								<p class="f-12 text-general pl-3 mt-3">Descripción</p>
								<el-input type="textarea" v-model="tipo.descripcion" show-word-limit rows="5" maxlength="500" class="w-100" size="small" />
							</div>
						<div class="col-auto pr-0 pt-2">
							<div class="bg-general mt-2 text-white d-middle-center cr-pointer px-4 br-4" :style="`height:32px;${!tipo.nombre || !tipo.descripcion ? 'opacity:0.5;pointer-events:none;' : ''}`" @click="guardar(tipo)">
								Crear
							</div>
						</div>
					</div>
					<div class="row mx-0 py-4" />
					<div v-for="tipo in tipos" :key="tipo.id" class="row mx-0 mb-4">
						<div class="col-12 px-0 d-middle">
							<div class="col px-0">
								<el-input v-model="tipo.nombre" :disabled="!tipo.editar" class="w-100" size="small" />
							</div>
							<div class="col-auto d-middle">
								<el-popover
								placement="top-start"
								trigger="click">
									<div class="row mx-0 align-items-center cr-pointer text-black px-2 br-4 item" style="height:25px;" @click="editarToggle(tipo)">
										Editar
									</div>
									<div class="row mx-0 align-items-center cr-pointer text-black px-2 br-4 item" :style="`height:25px;${!tipo.viviendas_count ? '' : 'opacity:0.5;pointer-events:none;'}`" @click="eliminarTipo(tipo)">
										Eliminar
									</div>
									<div slot="reference" class="btn-action br-4 border">
										<i v-if="tipo.editar" class="icon-confirmado text-blue-strong cr-pointer f-17" @click="guardar(tipo)" />
										<i v-else class="icon-opciones text-black cr-pointer f-17" />
									</div>
								</el-popover>
								<el-tooltip placement="bottom" content="Viviendas de este tipo" effect="light">
									<div class="col-auto pr-0 text-black">
										<i class="icon-inmobiliaria f-17" />
										{{tipo.viviendas_count}}
									</div>
								</el-tooltip>
							</div>
						</div>
						<div class="col-12 px-0 d-flex">
							<div class="col px-0 mt-3">
								<el-input type="textarea" v-model="tipo.descripcion" :disabled="!tipo.editar" class="item w-100" size="small" />
							</div>
							<div class="ml-5" style="width:68px;" />
						</div>
					</div>
				</div>
			</div>
		</div>
        <!-- Partials -->
		<modal ref="modalImportarPlantilla" titulo="Importar plantilla" no-aceptar adicional="Cargar" @adicional="cargarExcel">
			<div class="row mx-3 my-4">
				<el-upload
				ref="upload"
				action="#"
				:on-change="handlePreview"
				:on-remove="handleRemove"
				:on-exceed="handleExceed"
				:auto-upload="false"
				:multiple="false"
				:limit="1"
				accept=".xlsx, .xls"
				>
				<el-button class="bg-general text-white br-6"><i class="icon-arrow-up el-icon--right f-15"></i>Seleccionar archivo</el-button>
				<div slot="tip" class="el-upload__tip f-15">Solo se aceptan archivos Excel, extención (.xlsx)</div>
				</el-upload>
			</div>
		</modal>
		<modal-eliminar
		ref="modalEliminarVivienda"
		titulo="Eliminar tipo de vivienda"
		mensaje="¿Desea eliminar este tipo de vivienda?"
		@eliminar="eliminar"/>
    </section>
</template>

<script>
import Vue from 'vue'
import excel from 'vue-excel-export'
import Viviendas from '~/services/viviendas'
import { mapGetters } from 'vuex'

Vue.use(excel)
export default {
    data(){
			return{
        loading:false,
				nombre: '' ,
				editar_vivienda: false,
				estado_vivienda: false,
				tipos:[],
				tipo:{
					id_tipo : null,
					base_datos : null,
					nombre : '',
					descripcion : '',
					viviendas_count:0
				},
				tipoEliminar:{
					id_tipo : null,
					base_datos : null,
					nombre : '',
					descripcion : '',
					viviendas_count: 0
				},
				examplePlantilla:[
					{
						tipo:'',
						descripcion:'',
					}
				],
				file:null,
			}
    },
	mounted(){
		if(this.conjunto.base_datos){
			this.listar()
		}
	},
	computed:{
		...mapGetters({
			conjunto: 'conjuntos/conjunto',
		}),
	},

	watch:{
		conjunto:{
			handler(val){
				this.listar()
			},
			deep: true
		}
	},
    methods: {
			eliminarTipo(tipo){
				this.tipoEliminar = tipo
				this.$refs.modalEliminarVivienda.toggle();
			},
			async listar(){
				try{
					const params = {
						base_datos : this.conjunto.base_datos
					}
					const { data } = await Viviendas.listarTipos(params)
					this.tipos = data.data
				}catch(e){
					this.error_catch(e)
				}
			},
			async guardar(tipo){
				try{
					const payload = {
						id_tipo : tipo.id,
						base_datos : this.conjunto.base_datos,
						nombre : tipo.nombre,
						descripcion : tipo.descripcion
					}
					const { data } = await Viviendas.guardarTipo(payload)

					if(data.success){
						this.$notify({
							title: 'Guardar Tipo',
							message: data.mensaje,
							type: 'success'
						});
						this.limpiar()
						this.listar()
					}
          			this.loading = false

				}catch(e){
					this.error_catch(e)
          			this.loading = false
				}
			},
			editarToggle(tipo){

				if(tipo.editar){
					this.$set(tipo, 'editar', false)
				}else{
					this.$set(tipo, 'editar', true)
				}
			},
			limpiar(){
				this.tipo={
					id_tipo : null,
					base_datos : null,
					nombre : '',
					descripcion : '',
					viviendas_count: 0
				}
				this.tipoEliminar={
					id_tipo : null,
					base_datos : null,
					nombre : '',
					descripcion : '',
					viviendas_count: 0
				}
			},
			async eliminar(){
				try{
          			this.loading = true

					const params = {
						id_tipo : this.tipoEliminar.id,
						base_datos : this.conjunto.base_datos,
					}
					const { data } = await Viviendas.eliminarTipo(params)

					if(data.success){
						this.$notify({
							title: 'Eliminar Tipo',
							message: data.mensaje,
							type: 'success'
						});
						this.limpiar()
						this.listar()
						this.$refs.modalEliminarVivienda.toggle();
					}
          			this.loading = false

				}catch(e){
          			this.loading = false
					this.error_catch(e)
				}
			},
			modalImportar(){
				this.$refs.upload.clearFiles();
				this.$refs.modalImportarPlantilla.toggle();
			},
			handleRemove(file, fileList) {
				this.file = null;
			},
			handlePreview(file) {
				this.file = file.raw;
			},
			handleExceed(files, fileList) {
				this.$message.warning(`Solo se puede seleccionar 1 archivo`);
			},
			async cargarExcel(){
				try {
                    if (this.file == null) return;
					this.loading = true;

					let formData = new FormData();
					formData.append("base_datos", this.conjunto.base_datos)
					formData.append("archivo", this.file)

					const {data} = await Viviendas.importarExcel(formData);

					this.$refs.modalImportarPlantilla.toggle();
					this.listar();
					this.loading = false;
				}catch(e){
          			this.loading = false
					this.error_catch(e)
				}
			}
    }
}
</script>
<style lang="scss" scoped>
.item:hover{
    background-color:#EBECF0a8;
}
</style>